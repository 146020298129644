import React from "react";
import colors from "../configuration/theme/colors";

export const GalaxyTablet = ({ color = "navy-green" }) => {
  return (
    <svg width="1360" height="473" viewBox="0 0 1360 473" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1186.5 236.5C1186.5 260.66 1172.42 283.748 1146.8 304.816C1121.18 325.88 1084.08 344.873 1038.19 360.832C946.419 392.75 819.602 412.5 679.5 412.5C539.398 412.5 412.581 392.75 320.808 360.832C274.918 344.873 237.818 325.88 212.199 304.816C186.578 283.748 172.5 260.66 172.5 236.5C172.5 212.34 186.578 189.252 212.199 168.184C237.818 147.12 274.918 128.127 320.808 112.168C412.581 80.2505 539.398 60.5 679.5 60.5C819.602 60.5 946.419 80.2505 1038.19 112.168C1084.08 128.127 1121.18 147.12 1146.8 168.184C1172.42 189.252 1186.5 212.34 1186.5 236.5Z"
        stroke={colors[color]}
      />
      <path
        d="M1359.5 236.5C1359.5 268.944 1340.6 299.924 1306.24 328.17C1271.9 356.413 1222.17 381.87 1160.67 403.259C1037.68 446.034 867.736 472.5 680 472.5C492.264 472.5 322.322 446.034 199.332 403.259C137.834 381.87 88.1027 356.413 53.7553 328.17C19.4046 299.924 0.5 268.944 0.5 236.5C0.5 204.056 19.4046 173.076 53.7553 144.83C88.1027 116.587 137.834 91.13 199.332 69.7415C322.322 26.9662 492.264 0.5 680 0.5C867.736 0.5 1037.68 26.9662 1160.67 69.7415C1222.17 91.13 1271.9 116.587 1306.24 144.83C1340.6 173.076 1359.5 204.056 1359.5 236.5Z"
        stroke={colors[color]}
      />
      <path
        d="M1017.5 236C1017.5 252.083 1008.15 267.477 991.081 281.545C974.015 295.611 949.289 308.3 918.691 318.966C857.5 340.297 772.933 353.5 679.5 353.5C586.067 353.5 501.5 340.297 440.309 318.966C409.711 308.3 384.985 295.611 367.919 281.545C350.849 267.477 341.5 252.083 341.5 236C341.5 219.917 350.849 204.523 367.919 190.455C384.985 176.389 409.711 163.7 440.309 153.034C501.5 131.703 586.067 118.5 679.5 118.5C772.933 118.5 857.5 131.703 918.691 153.034C949.289 163.7 974.015 176.389 991.081 190.455C1008.15 204.523 1017.5 219.917 1017.5 236Z"
        stroke={colors[color]}
      />
      <g className="telesens-logo-icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M739.529 174.109L740.339 174.314C740.137 174.109 739.732 174.109 739.529 174.109Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M531.343 262.199C531.749 262.807 532.358 263.212 532.967 263.819C532.358 263.414 531.749 262.807 531.343 262.199Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M527.905 257.944C527.5 257.337 527.298 256.932 526.893 256.324C527.095 256.932 527.5 257.337 527.905 257.944Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M530.139 260.983C529.734 260.376 529.329 259.768 528.722 259.363C529.329 259.768 529.734 260.376 530.139 260.983Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M647.75 300.479C645.516 300.275 643.282 300.275 641.049 300.07C643.282 300.275 645.516 300.275 647.75 300.479Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M820.773 202.054C805.541 194.561 766.749 190.713 723.083 193.346C668.652 196.586 626.611 208.737 629.658 220.28C632.704 231.418 676.167 238.101 727.754 235.671L727.754 235.671C725.52 235.873 723.286 236.076 721.052 236.076C659.31 239.721 606.301 232.228 602.849 219.065C599.396 206.104 646.515 192.333 707.85 188.688C711.506 184.435 723.489 178.36 738.924 174.107C720.036 171.475 699.523 170.462 677.792 171.272C590.663 174.31 520.797 205.699 522.016 241.543C522.219 246.201 523.64 250.859 526.078 255.112C537.654 267.87 582.742 276.173 637.172 274.755C701.554 272.932 755.172 258.149 756.797 241.543C758.219 225.748 711.1 214.002 650.171 214.609C652.811 214.407 655.451 214.407 658.091 214.407C730.8 212.382 788.887 226.153 787.059 244.784C785.434 261.187 739.331 275.97 679.214 280.83C675.152 286.703 663.778 293.994 648.546 300.474C659.513 301.081 670.683 301.081 682.26 300.879C769.592 297.841 839.458 266.452 838.239 230.608C838.239 220.077 831.943 210.357 820.773 202.054ZM728.768 187.876C762.685 187.066 792.337 189.902 809.804 194.964C792.947 185.649 769.794 178.561 742.782 174.511C728.158 180.384 722.269 185.851 728.768 187.876ZM536.44 267.062C557.156 277.187 600.416 283.465 650.784 282.25C658.095 285.085 653.424 291.971 639.817 299.868C593.916 296.223 555.937 284.073 536.44 267.062Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M535.845 266.651C535.236 266.044 534.627 265.639 534.221 265.031C534.83 265.639 535.236 266.246 535.845 266.651Z"
          fill={colors[color]}
        />
      </g>
    </svg>
  );
};
const Galaxy = ({ color = "navy-green" }) => {
  return (
    <svg className="motion-path" width="1920" height="1046" viewBox="0 0 1920 1046" fill="none">
      <g clipPath="url(#clip0_64_845)">
        <path
          d="M1210.95 523.437C1210.95 555.668 1183.05 585.001 1137.59 606.305C1092.17 627.592 1029.38 640.771 960 640.771C890.621 640.771 827.835 627.592 782.412 606.305C736.949 585.001 709.052 555.668 709.052 523.437C709.052 491.207 736.949 461.874 782.412 440.569C827.835 419.283 890.621 406.104 960 406.104C1029.38 406.104 1092.17 419.283 1137.59 440.569C1183.05 461.874 1210.95 491.207 1210.95 523.437Z"
          stroke={colors[color]}
          id="motion-path-0"
        />
        <path
          d="M1336.15 523.438C1336.15 571.937 1294.24 616 1226.12 647.966C1158.04 679.914 1063.95 699.688 960 699.688C856.049 699.688 761.963 679.914 693.884 647.966C625.765 616 583.854 571.937 583.854 523.438C583.854 474.938 625.765 430.875 693.884 398.909C761.963 366.961 856.049 347.188 960 347.188C1063.95 347.188 1158.04 366.961 1226.12 398.909C1294.24 430.875 1336.15 474.938 1336.15 523.438Z"
          stroke={colors[color]}
          id="motion-path-1"
          opacity="0.6"
        />
        <path
          d="M1466.6 524.49C1466.6 589.549 1410.33 648.603 1318.98 691.422C1227.67 734.222 1101.5 760.708 962.104 760.708C822.709 760.708 696.534 734.222 605.227 691.422C513.881 648.603 457.604 589.549 457.604 524.49C457.604 459.43 513.881 400.376 605.227 357.557C696.534 314.757 822.709 288.271 962.104 288.271C1101.5 288.271 1227.67 314.757 1318.98 357.557C1410.33 400.376 1466.6 459.43 1466.6 524.49Z"
          stroke={colors[color]}
          id="motion-path-2"
          opacity="0.5"
        />
        <path
          d="M1592.85 523.438C1592.85 605.057 1522.21 679.103 1407.64 732.774C1293.1 786.427 1134.84 819.625 960 819.625C785.161 819.625 626.898 786.427 512.363 732.774C397.789 679.103 327.146 605.057 327.146 523.438C327.146 441.818 397.789 367.772 512.363 314.101C626.898 260.448 785.161 227.25 960 227.25C1134.84 227.25 1293.1 260.448 1407.64 314.101C1522.21 367.772 1592.85 441.818 1592.85 523.438Z"
          stroke={colors[color]}
          id="motion-path-3"
          opacity="0.4"
        />
        <path
          d="M1718.05 523.438C1718.05 621.326 1633.4 710.102 1496.17 774.435C1358.97 838.749 1169.41 878.542 960 878.542C750.588 878.542 561.026 838.749 423.835 774.435C286.604 710.102 201.948 621.326 201.948 523.438C201.948 425.549 286.604 336.773 423.835 272.44C561.026 208.126 750.588 168.333 960 168.333C1169.41 168.333 1358.97 208.126 1496.17 272.44C1633.4 336.773 1718.05 425.549 1718.05 523.438Z"
          stroke={colors[color]}
          id="motion-path-4"
          opacity="0.3"
        />
        <path
          d="M1844.3 523.438C1844.3 637.596 1745.51 741.101 1585.44 816.095C1425.4 891.071 1204.27 937.458 960 937.458C715.725 937.458 494.6 891.071 334.563 816.095C174.485 741.101 75.6979 637.596 75.6979 523.438C75.6979 409.279 174.485 305.774 334.563 230.78C494.6 155.804 715.725 109.417 960 109.417C1204.27 109.417 1425.4 155.804 1585.44 230.78C1745.51 305.774 1844.3 409.279 1844.3 523.438Z"
          stroke={colors[color]}
          id="motion-path-5"
          opacity="0.2"
        />
        <path
          d="M1969.5 523.438C1969.5 653.865 1856.7 772.099 1673.97 857.756C1491.27 943.393 1238.85 996.375 960 996.375C681.153 996.375 428.728 943.393 246.034 857.756C63.3006 772.099 -49.5 653.865 -49.5 523.438C-49.5 393.01 63.3006 274.776 246.034 189.119C428.728 103.482 681.153 50.5 960 50.5C1238.85 50.5 1491.27 103.482 1673.97 189.119C1856.7 274.776 1969.5 393.01 1969.5 523.438Z"
          stroke={colors[color]}
          id="motion-path-6"
          opacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1008.77 465.876C1042.69 465.066 1072.34 467.902 1089.8 472.964C1072.95 463.649 1049.79 456.561 1022.78 452.511C1008.16 458.384 1002.27 463.851 1008.77 465.876Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M807.905 535.945C807.5 535.337 807.297 534.932 806.892 534.325C807.095 534.932 807.5 535.337 807.905 535.945Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1100.77 480.054C1085.54 472.561 1046.75 468.713 1003.08 471.346C948.652 474.586 906.611 486.737 909.658 498.28C912.704 509.418 956.167 516.101 1007.75 513.671C1005.52 513.873 1003.29 514.076 1001.05 514.076C939.31 517.721 886.301 510.228 882.849 497.065C879.396 484.104 926.515 470.333 987.85 466.688C991.506 462.435 1003.49 456.36 1018.92 452.107C1000.04 449.475 979.523 448.462 957.792 449.272C870.663 452.31 800.797 483.699 802.016 519.543C802.219 524.201 803.64 528.859 806.078 533.112C817.654 545.87 862.742 554.173 917.172 552.755C981.554 550.932 1035.17 536.149 1036.8 519.543C1038.22 503.748 991.1 492.002 930.171 492.609C932.811 492.407 935.451 492.407 938.091 492.407C1010.8 490.382 1068.89 504.153 1067.06 522.784C1065.43 539.187 1019.33 553.97 959.214 558.83C955.152 564.703 943.778 571.994 928.546 578.474C939.513 579.081 950.683 579.081 962.26 578.879C1049.59 575.841 1119.46 544.452 1118.24 508.608C1118.24 498.077 1111.94 488.357 1100.77 480.054Z"
          fill={colors[color]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M930.784 560.25C880.416 561.465 837.156 555.187 816.44 545.062C835.937 562.073 873.916 574.223 919.817 577.868C933.424 569.97 938.095 563.085 930.784 560.25Z"
          fill={colors[color]}
        />
      </g>
    </svg>
  );
};

export default Galaxy;
