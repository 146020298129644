import { Formik } from "formik";
import { useEffect, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN } from "../common/constants";
import { settingsUpdate } from "../configuration/redux/Settings.slice";
import { Del, Get, Post } from "../configuration/server";
import Analytics from "./Analytics";
import { CreateUser, FormInput, WindowSection } from "./common";
import { ColorPicker } from "./common/ListPicker";

const Settings = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { layout, auth } = useSelector((state) => state);
  const { cookies } = useSelector(({ settings }) => settings);

  useEffect(() => {
    ref.current.setValues(cookies);
  }, [cookies]);

  return (
    <div className="p12">
      <Formik
        innerRef={ref}
        initialValues={{ ...cookies }}
        onSubmit={(val) => dispatch(settingsUpdate({ cookies: val, language: layout.language }))}>
        {({ handleChange, values, handleSubmit }) => (
          <WindowSection label="Cookies" right={[{ name: "Save", fn: handleSubmit }]}>
            <section className="section-divider row">
              <ColorPicker name="background" value={values.bg} onChange={handleChange("bg")} />
              <ColorPicker isFont name="text" value={values.color} onChange={handleChange("color")} />
            </section>
            <section className="section-divider">
              <div className="column">
                <FormInput label="title" value={values.title} onChange={handleChange("title")} />
                <FormInput label="text" value={values.text} onChange={handleChange("text")} />
                <FormInput label="label" value={values.label} onChange={handleChange("label")} />
                <FormInput label="to" value={values.to} onChange={handleChange("to")} />
              </div>
            </section>
          </WindowSection>
        )}
      </Formik>
      {auth.role === ADMIN ? <CreateUser /> : null}
      <Postmen />
      <Analytics />
    </div>
  );
};
function reducer(pre, { type, data }) {
  const changed = { list: [...pre.list], update: Date.now() };
  switch (type) {
    case "CHANGE":
      changed.list[data[0]][data[1]] = data[2];
      break;
    case "DEL":
      changed.list.splice(data, 1);
      break;
    case "SET":
      changed.list = data;
      break;
  }
  return changed;
}
function Postmen() {
  const [state, setState] = useReducer(reducer, { list: [], update: 0 });
  useEffect(() => {
    Get("allPostmen", (v) => setState({ type: "SET", data: v }));
  }, []);
  return (
    <div>
      <WindowSection label="Postmen">
        <section className="section-divider col">
          {state.list.map((props, i) => (
            <div key={props.id} className="flex-row justify-between">
              <FormInput label="type" value={props.type} onChange={(v) => setState({ type: "CHANGE", data: [i, "type", v] })} />
              <FormInput label="owner" value={props.owner} onChange={(v) => setState({ type: "CHANGE", data: [i, "owner", v] })} />
              <FormInput label="receiver" value={props.receiver} onChange={(v) => setState({ type: "CHANGE", data: [i, "receiver", v] })} />
              <div className="p24 flex gap8">
                <div onClick={() => setState({ type: "CHANGE", data: [i, "active", !props.active] })} className="bar-button pointy">
                  <span>{props.active ? "Deactivate" : "Activate"}</span>
                </div>
                <div onClick={() => Post(["mails/edit-postman", props.id], props)} className="bar-button pointy">
                  <span>Save</span>
                </div>
                <div
                  onClick={() => {
                    Del(["mails/delete-postman", props.id], () => {
                      setState({ type: "DEL", data: i });
                    });
                  }}
                  className="bar-button pointy">
                  <span>Del</span>
                </div>
              </div>
            </div>
          ))}
        </section>
      </WindowSection>
    </div>
  );
}
export default Settings;
