export const BlogPreview = {
  title: "",
  subTitle: "",
  desc: "",
  label: "",
  author: "",
  url: "",
  to: "",
  theme: "",
  // createdAt: "",
  authorRole: "",
  bg: "white",
  color: "black",
  visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
};
export const Sections = {
  BlogPreview: BlogPreview,
  Banner: {
    labels: "",
    speed: 30,
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Counter: {
    counters: [{ target: 0, label: "" }],
    bg: "navy-green",
    color: "white",
    speed: 3,
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  ContactUs: {
    bg: "white",
    color: "black",
    phoneLabel: "Phone number",
    emailLabel: "E-mail",
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    checkboxLabel: "I agree with information",
    checkboxLabel2: "I agree with information 2",
    messageLabel: "Message",
    promptTitle: "",
    promptMessage: "",
    to: "",
    clutchLink: "",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Footer: {
    copyright: "",
    phonesTitle: "",
    emailsTitle: "",
    followsTitle: "",
    addressesTitle: "",
    pages: [{ label: "", to: "" }],
    phones: [{ label: "", to: "" }],
    emails: [{ label: "", to: "" }],
    addresses: [{ label: "", to: "" }],
    follows: [{ label: "", to: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Gallery: {
    heading: "",
    innerHeading: "",
    items: [{ title: "", url: "", desc: "", links: [] }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Header: {
    links: [{ label: "", url: "", to: "" }],
    subLinks: [{ label: "", url: "", to: "" }],
    follows: [{ label: "", url: "", to: "" }],
    bg: "navy-green",
    color: "white",
    headerBg: "white",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
    localeToggle: { bg: "", color: "white", activeColor: "navy-green", activeBg: "white", borderColor: "white", borderWidth: "1" },
  },
  AnimatedHero: {
    text: "",
    desc: "",
    bg: "navy-green",
    color: "white",
    getInTouch: "",
    wallpaper: "",
    scrollStyle: "",
    wallpaperStyle: "",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Hero: {
    text: "",
    desc: "",
    bg: "navy-green",
    color: "white",
    getInTouch: "",
    wallpaper: "",
    scrollStyle: "",
    wallpaperStyle: "",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Industries: {
    heading: "",
    cards: [{ label: "", url: "", to: "", textColor: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  NotFound: {
    title: "",
    bg: "white",
    color: "black",
  },
  Post: {
    id: "",
    bg: "white",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Partners: {
    partners: [{ label: "", url: "", to: "", styleProps: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Quotes: {
    quotes: [{ title: "", desc: "", author: "", company: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  ServicesTree: {
    innerBlock: BlogPreview,
    root: {
      root: { bg: "navy-green", color: "white" },
      first: { bg: "soft-blue", color: "black" },
      second: { bg: "yellow", color: "black" },
      third: { bg: "light-blue", color: "black" },
      fourth: { bg: "navy-green", color: "white" },
      fifth: { bg: "navy-green", color: "white" },
      sixth: { bg: "navy-green", color: "white" },
    },
    variants: [{ parent: "", pointer: "", title: "", desc: "", url: "", secName: "", secTitle: "", secDesc: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Subscribe: {
    bg: "",
    color: "",
    emailLabel: "",
    nameLabel: "",
    checkboxLabel: "",
    checkboxLabel2: "I agree with information 2",
    promptTitle: "",
    promptMessage: "",
    to: "",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Services: {
    heading: "",
    products: [{ title: "", url: "", to: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Technologies: {
    heading: "",
    technologies: [{ title: "", variants: [{ label: "", url: "", to: "" }] }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Values: {
    values: [{ title: "", desc: "", url: "" }],
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  Vacancies: {
    vacancies: [
      {
        title: "",
        heading: "",
        requirements: [{ title: "", desc: "" }],
        tags: [
          { name: "", value: "" },
          { name: "", value: "" },
          { name: "", value: "" },
        ],
      },
    ],
    vacancyForm: {
      phoneLabel: "Phone number",
      emailLabel: "E-mail",
      firstNameLabel: "First name",
      lastNameLabel: "Last name",
      checkboxLabel: "I agree with information",
      checkboxLabel2: "I agree with information 2",
      messageLabel: "Message",
      cvLabel: "Message",
      promptTitle: "",
      promptMessage: "",
      applyButton: "",
      to: "",
    },
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  },
  PDF: {
    bg: "white",
    title: "",
    color: "black",
    name: "",
    url: "",
    visibilityOptions: { tablet: false, mobile: false, desktop: false, laptop: false },
  }
};
